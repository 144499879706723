import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faInfoCircle, faQuestionCircle, faCheckCircle, faTimesCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { registerUser } from "../../../api/private/users";
import { createCompanies } from "../../../api/private/companies";
import { createTransactions } from "../../../api/private/transactions";
import { Carousel, Accordion, Col, Row, Form, Modal, Card, Button, Container, Table } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import PersonalDetailsForm from "../../../components/PersonalDetailsForm";
import { Routes } from "../../../routes";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import { getAllPackagesPublic } from "../../../api/private/packages";
import SignatureCanvas from 'react-signature-canvas';
import PackageSelectionForm from "../../../components/PackageSelectionForm";
import CompanyDetailsForm from "../../../components/CompanyDetailsForm";
import moment from 'moment';  // Import moment.js to handle date manipulation

const SignupWizard = () => {
  const [showCompareModal, setShowCompareModal] = useState(false);
  const handleCompareModalOpen = () => setShowCompareModal(true);
  const handleCompareModalClose = () => setShowCompareModal(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [signatureType, setSignatureType] = useState('hand-signature');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [addonsSummary, setAddonsSummary] = useState([]);
  const [showSignatureAccordion, setShowSignatureAccordion] = useState(false);
  const [signature, setSignature] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [personalDetails, setPersonalDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    accountType: '',
    role: '',
    approved: ''
  });
  const [debitOrderDetails, setDebitOrderDetails] = useState({
    idNumber: '',
    bankName: '',
    accountNumber: '',
    branchCode: '',
    accountType: ''
  });
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    legal_name: '',
    trading_name: '',
    registration_number: '',
    vat_number: '',
    primary_name: '',
    primary_surname: '',
    primary_designation: '',
    contact_number: '',
    primary_email: '',
    registered_address: '',
    po_box: '',
    billing_address: '',
    social_links: {
      linkedin: 'https://',
      facebook: 'https://',
      instagram: 'https://',
      twitter: 'https://',
    }
  });
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isAnnual, setIsAnnual] = useState(false);
  const [addons, setAddons] = useState({
    addonCompanies: 0,
    addonBrands: 0,
    addonProducts: 0,
    addonUsers: 0,
    addonChats: 0,
  });
  const [paymentMethod, setPaymentMethod] = useState('payfast');
  const [paymentReference, setPaymentReference] = useState('');
  const [ipAddress, setIpAddress] = useState(null);
  const history = useHistory();
  const priceFieldMapping = {
    addonCompanies: 'addon_companies_price',
    addonBrands: 'addon_brands_price',
    addonProducts: 'addon_products_price',
    addonUsers: 'addon_users_price',
    addonChats: 'addon_chats_price',
  };

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api64.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip); // Store IP address in state
      } catch (error) {
        console.error("Error fetching IP address:", error);
        toast.error("Failed to fetch IP address.");
      }
    };

    fetchIpAddress();
  }, [])

  useEffect(() => {
    if (currentStep === 3) {
      loadPackages();
    }
  }, [currentStep]);

  useEffect(() => {
    if (selectedPackage) {
      setAddons({
        addonCompanies: 0,
        addonBrands: 0,
        addonProducts: 0,
        addonUsers: 0,
        addonChats: 0,
      });
    }
  }, [isAnnual, selectedPackage]);

  useEffect(() => {
    const selectedPackageData = packages.find(pkg => pkg._id === selectedPackage);

    if (selectedPackageData) {
      const summary = Object.keys(addons).map(key => {
        const addonName = key.replace(/_/g, ' ');
        const quantity = addons[key];
        const priceField = priceFieldMapping[key];
        const unitPrice = parseFloat(selectedPackageData?.[priceField] || 0); // Safe navigation
        const totalPrice = calculateAddonTotal(quantity, unitPrice);
        return { name: addonName, quantity, unitPrice, totalPrice };
      });
      setAddonsSummary(summary);
    }
  }, [addons, selectedPackage, isAnnual]);

  const handleTermsAcceptance = async () => {
    setShowTermsModal(false);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const getIPAddress = async () => {
    const response = await fetch('https://api64.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  };

  const validatePassword = (password) => {
    const criteria = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordCriteria(criteria);

    // Calculate strength as the number of criteria met
    const strength = Object.values(criteria).filter(Boolean).length;
    setPasswordStrength(strength);
  };

  const calculateTotalAddonsPrice = () => {
    return addonsSummary.reduce((total, addon) => total + addon.totalPrice, 0);
  };

  const handleSubmitAccordion = async () => {
    if (!agreedToTerms || !signature) {
      toast.error("Please agree to the terms and provide a signature.");
      return;
    }

    const ipAddress = await getIPAddress();
    const currentDateTime = new Date().toLocaleString();

    const selectedPackageData = packages.find(pkg => pkg._id === selectedPackage);

    if (!selectedPackageData) {
      toast.error("Please select a valid package.");
      return;
    }

    const addonsWithDetails = {};
    Object.keys(addons).forEach(key => {
      const quantity = addons[key];
      const priceField = priceFieldMapping[key];
      const unitPrice = parseFloat(selectedPackageData[priceField] || 0);
      const totalPrice = calculateAddonTotal(quantity, unitPrice);

      addonsWithDetails[key] = {
        quantity,
        unit_price: unitPrice,
        total_price: totalPrice,
      };
    });

    const totalAddonsPrice = Object.values(addonsWithDetails).reduce((total, addon) => total + addon.total_price, 0);
    const packagePrice = isAnnual ? parseFloat(selectedPackageData.annual_price || 0) : parseFloat(selectedPackageData.monthly_price || 0);
    const grandTotal = packagePrice + totalAddonsPrice;

    const transactionData = {
      user_id: personalDetails.user_id,
      package_id: selectedPackageData._id,
      package_name: selectedPackageData.title,
      is_annual: isAnnual,
      amount: grandTotal,
      addons: addonsWithDetails,
      total_addons_price: totalAddonsPrice,
      package_price: packagePrice,
      currency: "ZAR",
      transaction_date: currentDateTime,
      payment_method: paymentMethod,
      debit_order_details: debitOrderDetails,
    };

    const signupData = {
      personalDetails,
      companyDetails,
      selectedPackage: selectedPackageData,
      paymentDetails: {
        transactionId: "", // This can be updated later if needed
        amount: grandTotal,
        date: currentDateTime,
      },
      debitOrderDetails,
      agreedToTerms,
      addonsSummary,
      isAnnual,
    };

    try {
      // Save data to localStorage
      localStorage.setItem('signupData', JSON.stringify(signupData));

      toast.success(`Form submitted successfully on ${currentDateTime} from IP: ${ipAddress}`);
      history.push('/signup-success');
    } catch (error) {
      toast.error("There was an issue saving the form data.");
      console.error("Error saving form data:", error);
    }
  };


  function loadPackages() {
    getAllPackagesPublic().then((_resp) => {
      console.log("API Response for Packages:", _resp.data);
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        console.log("Loaded Packages:", records);
        setPackages(records);
        if (records.length > 0) {
          setSelectedPackage(records[0]._id);
        }
      } else {
        console.warn("Failed to load packages:", _resp.data);
      }
    }).catch((error) => {
      console.error("Error fetching packages:", error);
      toast.error('Failed to load packages');
    });
  }

  const handleNext = () => setCurrentStep(currentStep + 1);
  const handlePrevious = () => setCurrentStep(currentStep - 1);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (currentStep === 0) {
      if (personalDetails.accountType) {
        handleNext();
      } else {
        toast.error("Please select an account type before proceeding.");
      }
      return;
    }

    if (currentStep === 1) {
      if (personalDetails.accountType === 'seller') {
        handleNext();
      } else {
        createUserAcc(personalDetails);
      }
    } else if (currentStep === 2 || currentStep === 3 || currentStep === 4) {
      handleNext();
    } else if (currentStep === 5) {
      // If payment method is 'other', show the terms modal first
      if (paymentMethod === 'other') {
        setShowTermsModal(true);
      } else {
        // Otherwise, process the payment immediately
        processPayment();
      }
    }
  };

  function createUserAcc(personalDetails, companyDetails) {
    registerUser(personalDetails.firstName, personalDetails.lastName, personalDetails.email, personalDetails.mobileNumber, personalDetails.password, personalDetails.role, personalDetails.approved)
      .then((userResponse) => {
        if (userResponse.data.Result === 'OK' || userResponse.data.Result === 'SUCCESS') {
          toast.success('User created successfully');

          const companyData = {
            legal_name: companyDetails.legal_name,
            trading_name: companyDetails.trading_name,
            registration_number: companyDetails.registration_number,
            vat_number: companyDetails.vat_number,
            location: {
              address: companyDetails.registered_address,
              area_code: ""
            },
            industry_type: "Undefined",
            primary_name: companyDetails.primary_name,
            primary_surname: companyDetails.primary_surname,
            primary_designation: companyDetails.primary_designation,
            contact_number: companyDetails.contact_number,
            primary_email: companyDetails.primary_email,
            registered_address: companyDetails.registered_address,
            po_box: companyDetails.po_box,
            billing_address: companyDetails.billing_address,
            company_type: "",
            company_bio: "",
            social_links: companyDetails.social_links,
            bbbee: {
              level: "",
              expiry_date: "",
              certificate: ""
            },
            created_by: {
              email: personalDetails.email,
              doc_id: userResponse.data.user_id,
            },
            brands: [],
            website_url: "",
            dealer_network: false,
            approved: false
          };

          return createCompanies(companyData).then((companyResponse) => {
            if (companyResponse.data.Result === 'OK' || companyResponse.data.Result === 'SUCCESS') {
              toast.success('Company created successfully');
            } else {
              toast.error(`Company not created, ${companyResponse.data.Message}`);
            }
          });
        } else {
          toast.error(`User not created, ${userResponse.data.Message}`);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('An error occurred during the registration process.');
      });
  }

  const isValidRsaId = (idNumber) => {
    if (idNumber.length !== 13) {
      return false;
    }

    // Regular expression for valid date and basic structure
    const regex = new RegExp(/(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/);

    if (!regex.test(idNumber)) {
      return false;
    }

    // Luhn algorithm check
    let checkSum = 0;
    let multiplier = 1;

    for (let i = 0; i < 13; ++i) {
      let tempTotal = parseInt(idNumber.charAt(i)) * multiplier;

      if (tempTotal > 9) {
        tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
      }

      checkSum += tempTotal;
      multiplier = (multiplier === 1) ? 2 : 1; // Toggle multiplier between 1 and 2
    }

    return checkSum % 10 === 0;
  };

  const processPayment = () => {
    const selectedPackageData = packages.find(pkg => pkg._id === selectedPackage);
    const price = isAnnual
      ? parseFloat(selectedPackageData.annual_price || 0)
      : parseFloat(selectedPackageData.monthly_price || 0);

    const addonCost = (
      (addons.addonCompanies * parseFloat(selectedPackageData.addon_companies_price || 0)) +
      (addons.addonBrands * parseFloat(selectedPackageData.addon_brands_price || 0)) +
      (addons.addonProducts * parseFloat(selectedPackageData.addon_products_price || 0)) +
      (addons.addonUsers * parseFloat(selectedPackageData.addon_users_price || 0)) +
      (addons.addonChats * parseFloat(selectedPackageData.addon_chats_price || 0))
    );

    const totalPrice = price + addonCost;

    // Prepare data to store in localStorage
    const signupData = {
      personalDetails,
      companyDetails,
      selectedPackage: selectedPackageData,
      paymentDetails: {
        transactionId: "", // Will be updated post-payment if needed
        amount: totalPrice,
        date: new Date().toLocaleString(),
      },
      debitOrderDetails,
      agreedToTerms,
      addonsSummary,
      isAnnual,
    };

    try {
      // Save data to localStorage
      localStorage.setItem('signupData', JSON.stringify(signupData));

      // PayFast required data
      const payfastData = {
        merchant_id: "10035698",
        merchant_key: "cr3zvnu1omw7v",
        return_url: "http://localhost:3002/signup-success",
        cancel_url: "http://localhost:3002/signup-cancel",
        notify_url: "http://localhost:3002/notify",
        amount: totalPrice,
        item_name: `${selectedPackageData.title} with Add-ons`,
        email_address: personalDetails.email,
      };

      // Create the form dynamically
      const form = document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', 'https://sandbox.payfast.co.za/eng/process');
      form.style.display = 'none';

      // Add each property of payfastData as hidden input
      for (const key in payfastData) {
        if (payfastData.hasOwnProperty(key)) {
          const hiddenField = document.createElement('input');
          hiddenField.setAttribute('type', 'hidden');
          hiddenField.setAttribute('name', key);
          hiddenField.setAttribute('value', payfastData[key]);
          form.appendChild(hiddenField);
        }
      }

      // Append and submit the form
      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      toast.error("There was an issue saving the form data.");
      console.error("Error saving form data:", error);
    }
  };

  const calculateAddonTotal = (addonQty, addonPrice) => addonQty * (addonPrice || 0);

  // AddonsSelectionForm Component
  const AddonsSelectionForm = ({ selectedPackageData, addons, setAddons, isAnnual }) => {
    console.log('Selected Package Data:', selectedPackageData);

    const addonItems = [
      {
        title: 'Addon Companies',
        key: 'addonCompanies',
        price: isAnnual ? selectedPackageData.addon_companies_annual_price : selectedPackageData.addon_companies_price,
        tooltip: {
          headline: 'Companies Addon',
          text: 'This addon allows you to add additional companies to your package.'
        }
      },
      {
        title: 'Addon Brands',
        key: 'addonBrands',
        price: isAnnual ? selectedPackageData.addon_brands_annual_price : selectedPackageData.addon_brands_price,
        tooltip: {
          headline: 'Brands Addon',
          text: 'Add more brands to enhance your brand management capabilities.'
        }
      },
      {
        title: 'Addon Products',
        key: 'addonProducts',
        price: isAnnual ? selectedPackageData.addon_products_annual_price : selectedPackageData.addon_products_price,
        tooltip: {
          headline: 'Products Addon',
          text: 'Increase the number of products you can manage within your account.'
        }
      },
      {
        title: 'Addon Users',
        key: 'addonUsers',
        price: isAnnual ? selectedPackageData.addon_users_annual_price : selectedPackageData.addon_users_price,
        tooltip: {
          headline: 'Users Addon',
          text: 'Allows you to add additional users to your account for better team collaboration.'
        }
      },
      {
        title: 'Addon Chats',
        key: 'addonChats',
        price: isAnnual ? selectedPackageData.addon_chats_annual_price : selectedPackageData.addon_chats_price,
        tooltip: {
          headline: 'Chats Addon',
          text: 'Enable more chat capabilities to enhance customer interaction.'
        }
      }
    ];

    const subtotal = addonItems.reduce((acc, addon) => acc + calculateAddonTotal(addons[addon.key], addon.price), 0);

    return (
      <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Addon</th>
              <th>Price per Unit</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {addonItems.map((addon, index) => (
              <tr key={index}>
                <td>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-${addon.key}`}>
                        <strong>{addon.tooltip.headline}</strong>
                        <br />
                        {addon.tooltip.text}
                      </Tooltip>
                    }
                  >
                    <span>{addon.title}
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{ marginLeft: '10px', cursor: 'pointer', color: '#17a2b8' }} // Add some styling
                      />
                    </span>
                  </OverlayTrigger>
                </td>
                <td>R{addon.price}</td>
                <td>
                  <Form.Control
                    type="number"
                    value={addons[addon.key]}
                    onChange={e => setAddons({ ...addons, [addon.key]: parseInt(e.target.value) || 0 })}
                    min="0"
                  />
                </td>
                <td>R{calculateAddonTotal(addons[addon.key], addon.price)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col md={6} className="mt-3">
            <h5>Subtotal for Addons: R{subtotal}</h5>
          </Col>
        </Row>
      </>
    );
  };

  const calculateTotalPrice = () => {
    const selectedPackageData = packages.find(pkg => pkg._id === selectedPackage);
    if (!selectedPackageData) return 0;

    const packagePrice = isAnnual
      ? parseFloat(selectedPackageData.annual_price || 0)
      : parseFloat(selectedPackageData.monthly_price || 0);

    const addonCost = addonsSummary.reduce((total, addon) => total + addon.totalPrice, 0);

    return packagePrice + addonCost;
  };

  console.log('Addons:', addonsSummary);


  return (
    <main className="bg-primary min-vh-100">
      <ToastContainer hideProgressBar="false" />
      <section className="d-flex bg-primary align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-300">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center bg-primary">
            <Col xs={12} lg={9} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                {personalDetails.accountType === 'seller' && (
                  <div className="wizard-nav d-flex justify-content-center mb-4">
                    <ul className="nav nav-pills">
                      <li className={`nav-item ${currentStep === 1 ? 'active' : ''}`}>
                        <span className={`nav-link ${currentStep === 1 ? 'bg-orange text-white' : 'bg-primary text-white'}`}>1</span>
                      </li>
                      <li className={`nav-item ${currentStep === 2 ? 'active' : ''}`}>
                        <span className={`nav-link ${currentStep === 2 ? 'bg-orange text-white' : 'bg-primary text-white'}`}>2</span>
                      </li>
                      <li className={`nav-item ${currentStep === 3 ? 'active' : ''}`}>
                        <span className={`nav-link ${currentStep === 3 ? 'bg-orange text-white' : 'bg-primary text-white'}`}>3</span>
                      </li>
                      <li className={`nav-item ${currentStep === 4 ? 'active' : ''}`}>
                        <span className={`nav-link ${currentStep === 4 ? 'bg-orange text-white' : 'bg-primary text-white'}`}>4</span>
                      </li>
                      <li className={`nav-item ${currentStep === 5 ? 'active' : ''}`}>
                        <span className={`nav-link ${currentStep === 5 ? 'bg-orange text-white' : 'bg-primary text-white'}`}>5</span>
                      </li>
                    </ul>
                  </div>
                )}
                <Form className="mt-4" onSubmit={handleSubmit}>
                  {currentStep === 0 && (
                    <div>
                      <div className="step-heading"><h5>Select Account Type</h5></div>
                      <div className="step-subheading"><p>Select your role to begin the registration process and access the tools you need.</p></div>
                      <hr />
                      <Row className="justify-content-center">
                        <Col md={5} className="mb-4">
                          <Card
                            className={`buyer-card text-center cursor-pointer ${personalDetails.accountType === 'buyer' ? 'border-primary' : 'border-orange'}`}
                            onClick={() => setPersonalDetails({ ...personalDetails, accountType: 'buyer', role: 'buyer' })}
                          >
                            <Card.Body>
                              <Card.Title>Buyer</Card.Title>
                              <Card.Text>
                                Looking for forklifts and related equipment? Register to browse and purchase from trusted suppliers.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={5} className="mb-4">
                          <Card
                            className={`seller-card text-center cursor-pointer ${personalDetails.accountType === 'seller' ? 'border-primary' : 'border-orange'}`}
                            onClick={() => setPersonalDetails({ ...personalDetails, accountType: 'seller', role: 'seller' })}
                          >
                            <Card.Body>
                              <Card.Title>Seller</Card.Title>
                              <Card.Text>
                                Offering forklifts, parts, or services? Sign up to showcase your products and connect with buyers.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {currentStep === 1 && (
                    <div>
                      <div className="step-heading"><h5>Personal Details</h5></div>
                      <hr />
                      <PersonalDetailsForm
                        togglePasswordVisibility={togglePasswordVisibility}
                        passwordVisible={passwordVisible}
                        personalDetails={personalDetails}
                        setPersonalDetails={setPersonalDetails}
                        setPasswordStrength={setPasswordStrength}
                        setPasswordCriteria={setPasswordCriteria}
                        validatePassword={validatePassword}
                        passwordStrength={passwordStrength}
                        passwordCriteria={passwordCriteria}
                      />
                    </div>
                  )}
                  {currentStep === 2 && personalDetails.accountType === 'seller' && (
                    <div>
                      <div className="step-heading"><h5>Company Details</h5></div>
                      <hr />
                      <CompanyDetailsForm
                        companyDetails={companyDetails}
                        setCompanyDetails={setCompanyDetails}
                      />
                    </div>
                  )}

                  {currentStep === 3 && personalDetails.accountType === 'seller' && (
                    <div>
                      <div className="step-heading"><h5>Package Options</h5></div>
                      <hr />
                      <PackageSelectionForm
                        packages={packages}
                        selectedPackage={selectedPackage}
                        setSelectedPackage={setSelectedPackage}
                        isAnnual={isAnnual}
                        setIsAnnual={setIsAnnual}
                      />
                    </div>
                  )}

                  {currentStep === 4 && personalDetails.accountType === 'seller' && (
                    <>
                      <>
                        <div>
                          <div className="step-heading"><h5>Package Options</h5></div>
                          <hr />
                          <AddonsSelectionForm
                            selectedPackageData={packages.find(pkg => pkg._id === selectedPackage)}
                            addons={addons}
                            setAddons={setAddons}
                            isAnnual={isAnnual}
                          />
                          <h5>
                            {packages.find(pkg => pkg._id === selectedPackage)?.title || 'Package'} Cost: R
                            {packages.find(pkg => pkg._id === selectedPackage)
                              ? packages.find(pkg => pkg._id === selectedPackage)[isAnnual ? 'annual_price' : 'monthly_price']
                              : 0}
                            /{isAnnual ? 'p/a' : 'p/m'}
                          </h5>
                        </div>
                      </>
                    </>
                  )}

                  {currentStep === 5 && (
                    <div>
                      <h5>Review Your Selections</h5>

                      <h5>
                        Cost: R
                        {packages.find(pkg => pkg._id === selectedPackage)
                          ? packages.find(pkg => pkg._id === selectedPackage)[isAnnual ? 'annual_price' : 'monthly_price']
                          : 0}
                      </h5>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Addon</th>
                            <th>Quantity</th>
                            <th>Price per Unit</th>
                            <th>Total Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addonsSummary.map((addon, index) => (
                            <tr key={index}>
                              <td>{addon.name}</td>
                              <td>{addon.quantity}</td>
                              <td>R{addon.unitPrice.toFixed(2)}</td> {/* Unit price */}
                              <td>R{addon.totalPrice.toFixed(2)}</td> {/* Total price */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <h5>
                      </h5>
                      <h5>Total Package Cost: R{calculateTotalPrice()}/{isAnnual ? 'p/a' : 'p/m'}</h5>

                      <div className="paymentBottomSection" style={{ backgroundColor: '#f3f3f3f', padding: '1rem', marginTop: '1rem' }}>
                        {/* Payment Options */}
                        Please select your preferred method of payment.
                        <Form.Check
                          type="radio"
                          label="PayFast"
                          name="paymentMethod"
                          checked={paymentMethod === 'payfast'}
                          onChange={() => setPaymentMethod('payfast')}
                        />
                        <Form.Check
                          type="radio"
                          label="Debit Order"
                          name="paymentMethod"
                          checked={paymentMethod === 'other'}
                          onChange={() => setPaymentMethod('other')}
                        />
                      </div>
                    </div>
                  )}

                  <div className="d-flex justify-content-end">
                    {currentStep > 1 && personalDetails.accountType === 'seller' && (
                      <Button className="mx-2" variant="secondary" onClick={handlePrevious}>
                        Previous
                      </Button>
                    )}
                    <Button variant="primary" className="mx-2" type="submit">
                      {currentStep === 5 && personalDetails.accountType === 'seller'
                        ? 'Sign Up Now'
                        : personalDetails.accountType === 'buyer'
                          ? 'Submit'
                          : 'Next'}
                    </Button>
                  </div>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          {currentStep === 3 && (
            <Button
              className="compare-packages-btn"
              variant="brand-orange"
              onClick={handleCompareModalOpen}
              style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000, borderRadius: '15px' }}
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
              Compare Packages
            </Button>
          )}
          <Modal show={showCompareModal} onHide={handleCompareModalClose} size="xl" fullscreen>
            <Modal.Header closeButton>
              <Modal.Title>Compare Packages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Features</th>
                    {packages.map((pkg) => (
                      <th key={pkg._id}>{pkg.title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* Generate a list of all unique features */}
                  {(() => {
                    const featureSet = new Set();
                    packages.forEach((pkg) => {
                      pkg.features.forEach((feature) => {
                        featureSet.add(feature.text);
                      });
                    });
                    const allFeatures = Array.from(featureSet);

                    return allFeatures.map((featureText, index) => (
                      <tr key={index}>
                        <td>{featureText}</td>
                        {packages.map((pkg) => (
                          <td key={pkg._id}>
                            {pkg.features.some((f) => f.text === featureText) ? (
                              <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                            ) : (
                              <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />
                            )}
                          </td>
                        ))}
                      </tr>
                    ));
                  })()}

                  {/* Monthly Price Comparison */}
                  <tr>
                    <td>Monthly Price (Min - Max)</td>
                    {packages.map((pkg) => (
                      <td key={pkg._id}>
                        R{pkg.monthly_price} {/* Adjust as needed */}
                      </td>
                    ))}
                  </tr>

                  {/* Annual Price Comparison */}
                  <tr>
                    <td>Annual Price (Min - Max)</td>
                    {packages.map((pkg) => (
                      <td key={pkg._id}>
                        R{pkg.annual_price} {/* Adjust as needed */}
                      </td>
                    ))}
                  </tr>

                  {/* Add-ons Price Comparison */}
                  <tr>
                    <td>Add-on: Brands</td>
                    {packages.map((pkg) => (
                      <td key={pkg._id}>
                        R{pkg.addon_brands_price}
                      </td>
                    ))}
                  </tr>

                  <tr>
                    <td>Add-on: Companies</td>
                    {packages.map((pkg) => (
                      <td key={pkg._id}>
                        R{pkg.addon_companies_price}
                      </td>
                    ))}
                  </tr>

                  <tr>
                    <td>Add-on: Products</td>
                    {packages.map((pkg) => (
                      <td key={pkg._id}>
                        R{pkg.addon_products_price}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCompareModalClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>

        <Modal
          show={showTermsModal}
          onHide={() => setShowTermsModal(false)}
          size="lg"
          dialogClassName="modal-80w" // Add custom class to control width
          centered  // Center the modal on the screen
        >
          <Modal.Header closeButton>
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Terms and Conditions</Accordion.Header>
                <Accordion.Body>
                  <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    <p>
                      I/We, the undersigned, hereby authorize {companyDetails.legal_name}, to debit my/our bank account for the amount of
                      {selectedPackage ? ` R${calculateTotalPrice()}` : ' [Amount]'} on a recurring basis in accordance with the terms and conditions set out below:
                    </p>

                    <h5>Account Holder Information</h5>
                    <Form.Group>
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="fullName"
                        value={`${personalDetails.firstName} ${personalDetails.lastName}`}
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>ID Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="idNumber"
                        value={debitOrderDetails.idNumber || ''}
                        onChange={(e) => {
                          const idValue = e.target.value;
                          setDebitOrderDetails({ ...debitOrderDetails, idNumber: idValue });

                          if (!isValidRsaId(idValue)) {
                            // Handle invalid ID case (e.g., show an error message)
                            console.log("Invalid RSA ID");
                          } else {
                            // Handle valid ID case
                            console.log("Valid RSA ID");
                            toast.success('Id is valid')
                          }
                        }}
                        placeholder="Enter ID Number"
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Select
                        name="bankName"
                        value={debitOrderDetails.bankName || ''}
                        onChange={(e) => setDebitOrderDetails({ ...debitOrderDetails, bankName: e.target.value })}
                      >
                        <option value="" disabled>Select your bank</option> {/* Placeholder option */}
                        <option value="Absa">Absa</option>
                        <option value="First National Bank">First National Bank</option>
                        <option value="Capitec">Capitec</option>
                        <option value="Standard Bank">Standard Bank</option>
                        <option value="Nedbank">Nedbank</option>
                        <option value="Investec">Investec</option>
                        <option value="Merchant West">Merchant West</option>
                        <option value="Thymebank">Thymebank</option>
                        <option value="African Bank">African Bank</option>
                        <option value="Discovery Bank">Discovery Bank</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="accountNumber"
                        value={debitOrderDetails.accountNumber || ''}
                        onChange={(e) => setDebitOrderDetails({ ...debitOrderDetails, accountNumber: e.target.value })}
                        placeholder="Enter Account Number"
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Branch Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="branchCode"
                        value={debitOrderDetails.branchCode || ''}
                        onChange={(e) => setDebitOrderDetails({ ...debitOrderDetails, branchCode: e.target.value })}
                        placeholder="Enter Branch Code"
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Account Type</Form.Label>
                      <Form.Select
                        name="accountType"
                        value={debitOrderDetails.accountType || ''}
                        onChange={(e) => setDebitOrderDetails({ ...debitOrderDetails, accountType: e.target.value })}
                      >
                        <option value="" disabled>Select account type</option> {/* Placeholder option */}
                        <option value="Cheque">Cheque</option>
                        <option value="Savings">Savings</option>
                      </Form.Select>
                    </Form.Group>


                    <h5>Payment Details</h5>
                    <p>
                      Payment Frequency: {isAnnual ? 'Annually' : 'Monthly'} <br />
                      Amount: R{calculateTotalPrice()} <br />
                      First Payment Date: {moment().format('YYYY-MM-DD')} <br /> {/* Today's date */}
                      Recurring Payment Date: {moment().add(1, 'months').format('YYYY-MM-DD')} <br /> {/* One month from today */}
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Mandate Confirmation</Accordion.Header>
                <Accordion.Body>
                  <h5>Authorization and Agreement</h5>
                  <p>
                    I/We understand that this authority and mandate may be canceled by me/us by providing {companyDetails.legal_name} with
                    written notice at least 30 calendar days' before the scheduled debit date. I/We also understand that I/we
                    shall not be entitled to any refund of amounts which {companyDetails.legal_name} has withdrawn while this authority
                    was in force, if such amounts were legally owed to {companyDetails.legal_name}.
                  </p>

                  <p>
                    I/We agree that the debit order will continue until such time that the agreement is terminated or all
                    payments have been made.
                  </p>

                  <h5>Details for Agreement</h5>
                  <p><strong>Name:</strong> {personalDetails.firstName} {personalDetails.lastName}</p>
                  <p><strong>Date & Time:</strong> {new Date().toLocaleString()}</p>
                  <p><strong>IP Address:</strong> {ipAddress}</p>

                  <Form.Check
                    type="checkbox"
                    name="agreedToTerms"
                    label="I agree to the terms and conditions"
                    checked={agreedToTerms}
                    onChange={() => setAgreedToTerms(!agreedToTerms)}
                  />

                  <h6>Signature:</h6>
                  <sub>Please sign here</sub>
                  {/* Add a toggle between e-signature and hand signature */}
                  <Form.Group>
                    <Form.Label>Select Signature Type</Form.Label>
                    <Form.Check
                      type="radio"
                      label="Use e-signature (based on name)"
                      name="signatureType"
                      onChange={() => setSignatureType('e-signature')}
                      checked={signatureType === 'e-signature'}
                    />
                    <Form.Check
                      type="radio"
                      label="Hand signature (Sign in the box below)"
                      name="signatureType"
                      onChange={() => setSignatureType('hand-signature')}
                      checked={signatureType === 'hand-signature'}
                    />
                  </Form.Group>

                  {/* Conditional rendering of signature based on the selected type */}
                  {signatureType === 'hand-signature' ? (
                    <>
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas', style: { border: '1px solid #efefef', background: '#efefef' } }}  // Light border for the signature canvas
                        ref={(ref) => setSignature(ref)}
                      />
                    </>
                  ) : (
                    <>
                      <p><strong>E-Signature:</strong> {personalDetails.firstName} {personalDetails.lastName}</p>
                    </>
                  )}

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowTermsModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmitAccordion}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

      </section>
    </main>
  );
}

export default SignupWizard;
