import React from 'react'
import { Carousel, Accordion, Col, Row, Form, Modal, Card, Button, Container, InputGroup, ToggleButton, ButtonGroup, Table } from '@themesberg/react-bootstrap';
import { faAngleLeft, faQuestionCircle, faIdCard, faEnvelope, faUnlockAlt, faEyeSlash, faEye, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PersonalDetailsForm = ({ validatePassword, passwordStrength, passwordCriteria, setPasswordStrength, setPasswordCriteria, passwordVisible, togglePasswordVisibility, personalDetails, setPersonalDetails }) => (
  <Row>
    <Col md={12}>
      <Form.Group id="account" className="mb-4">
        <Form.Label>Account Type</Form.Label>
        <InputGroup>
          <Form.Control
            as="select"
            required
            value={personalDetails.accountType}
            onChange={e => {
              const selectedAccountType = e.target.value;
              let role = "";

              if (selectedAccountType === "buyer") {
                role = "buyer";
              } else if (selectedAccountType === "seller") {
                role = "seller";
              }

              setPersonalDetails({
                ...personalDetails,
                accountType: selectedAccountType,
                role: role
              });
            }}
          >
            <option value="">Select Account Type</option>
            <option value="buyer">Buyer</option>
            <option value="seller">Seller</option>
          </Form.Control>
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="first_name" className="mb-4">
        <Form.Label>Your First Name</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faIdCard} />
          </InputGroup.Text>
          <Form.Control
            autoFocus
            name="first_name"
            required
            type="text"
            placeholder="First Name"
            value={personalDetails.firstName}
            onChange={e => setPersonalDetails({ ...personalDetails, firstName: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="last_name" className="mb-4">
        <Form.Label>Your Last Name</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faIdCard} />
          </InputGroup.Text>
          <Form.Control
            required
            name="last_name"
            type="text"
            placeholder="Last Name"
            value={personalDetails.lastName}
            onChange={e => setPersonalDetails({ ...personalDetails, lastName: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="email_address" className="mb-4">
        <Form.Label>Your Email</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faEnvelope} />
          </InputGroup.Text>
          <Form.Control
            autoFocus
            name="email"
            required
            type="email"
            placeholder="Enter business email address"
            value={personalDetails.email}
            onChange={e => setPersonalDetails({ ...personalDetails, email: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="password" className="mb-4">
        <Form.Label>Your Password</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faUnlockAlt} />
          </InputGroup.Text>
          <Form.Control
            required
            type={passwordVisible ? "text" : "password"} // Dynamically change type
            name="password"
            placeholder="Password"
            value={personalDetails.password}
            onChange={(e) => {
              const newPassword = e.target.value;
              setPersonalDetails({ ...personalDetails, password: newPassword });
              validatePassword(newPassword);
            }}
          />
          <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </Col>
    <div className="password-strength-meter mt-2">
            <div
              className="strength-bar"
              style={{
                width: `${(passwordStrength / 5) * 100}%`,
                height: "5px",
                backgroundColor:
                  passwordStrength === 5
                    ? "green"
                    : passwordStrength >= 3
                      ? "orange"
                      : "red",
              }}
            ></div>
            <ul className="password-criteria">
              <li style={{ color: passwordCriteria.length ? "green" : "red" }}>
                <FontAwesomeIcon icon={passwordCriteria.length ? faCheckCircle : faTimesCircle} />
                At least 8 characters
              </li>
              <li style={{ color: passwordCriteria.uppercase ? "green" : "red" }}>
                <FontAwesomeIcon icon={passwordCriteria.uppercase ? faCheckCircle : faTimesCircle} />
                At least one uppercase letter
              </li>
              <li style={{ color: passwordCriteria.lowercase ? "green" : "red" }}>
                <FontAwesomeIcon icon={passwordCriteria.lowercase ? faCheckCircle : faTimesCircle} />
                At least one lowercase letter
              </li>
              <li style={{ color: passwordCriteria.number ? "green" : "red" }}>
                <FontAwesomeIcon icon={passwordCriteria.number ? faCheckCircle : faTimesCircle} />
                At least one number
              </li>
              <li style={{ color: passwordCriteria.symbol ? "green" : "red" }}>
                <FontAwesomeIcon icon={passwordCriteria.symbol ? faCheckCircle : faTimesCircle} />
                At least one special character
              </li>
            </ul>
          </div>
  </Row >
);

export default PersonalDetailsForm;