import { doPrivatePostRequest, doPrivateGetRequest, doPublicGetRequest, doPrivateDelRequest } from "./requests";

export const createPackages = (_email, _userId) => {

    const payload = {
            'linked_user': {
                'email': _email,
                'doc_id': _userId
            },
            'linked_companies': []
    }

    return doPrivatePostRequest(payload, 'packages/create')
}

export const updatePackages = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `packages/update/${_doc_id}`)
}

export const getPackages = (_doc_id) => {
    return doPrivateGetRequest(`packages/${_doc_id}`)
}

export const getPackagesById = (_doc_id) => {
    return doPrivatePostRequest({}, `packages/${_doc_id}`);
  };  

export const getAllPackagesPublic = () => {
    return doPublicGetRequest('packages/public')
}

export const getAllPackages = () => {
    return doPrivateGetRequest('packages')
}

export const deletePackages = (_doc_id) => {
    return doPrivateDelRequest(`packages/delete/${_doc_id}`)
}
